import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Viewblocker extends Component {

    render() {
        return (
            <div className={`viewblocker ${this.props.className || ''}`}>
                <div
                    className='viewblocker__blocker'
                    onClick={(event) => {
                        if (this.props.onBlockerClick) {
                            this.props.onBlockerClick(event);
                        }
                    }}
                ></div>
                <div className='viewblocker__children'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


Viewblocker.propTypes = {
    children: PropTypes.object.isRequired,

    className: PropTypes.string,
    onBlockerClick: PropTypes.func,
};

export default Viewblocker;