import {decorate, configure} from 'mobx';

configure({enforceActions: 'always'});

class InputStore {

    constructor() {
        this.labelClass = 'field__label';
        this.wrapClass = 'field';
        this.fieldWrapClass = 'field__wrap';
        this.inputRequiredClass = 'input-required';
        this.fieldErrorClass = 'field__error';
    }
}

decorate(InputStore, {});

export default new InputStore();