import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { observer } from 'mobx-react';
import { request } from 'js/request';
import AlertBar from 'js/components/alertBar';
import Footer from 'js/components/footer';
import Topnav from 'js/components/topnav';
import Modal from 'js/components/modal';
import PreLoader from 'js/components/preloader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import setup from 'js/setup';
import store from 'js/stores/App';
import userStore from 'js/stores/User';
import ViewBlocker from 'js/components/viewblocker';

setup();

class App extends Component {

    constructor(props) {
        super(props);
        this.appContainerRef = React.createRef();
        store.setAppLoading(true);
        this.counter = 0;
    }

    componentDidUpdate() {
        store.setAppContainerRef(this.appContainerRef);
    }
    
    componentDidMount() {

        store.loadDependency(() => {
            request({
                method: 'GET',
                url: window.apiurls.getAllWords
            }, (response) => {
                if (response) {
                    window.appWords = response.data;
                    store.dependencyLoaded();
                }
            });
        });

        store.loadDependency(() => {
            request({
                method: 'GET',
                url: window.apiurls.getConfig
            }, (response) => {
                if (response) {
                    window.uiConfig = response.data;
                    store.dependencyLoaded();
                }
            });
        });
    }

    displayAlertBar(isError) {
        var message = isError ? store.errorBar : store.alertBar;

        return (
            <AlertBar
                content={message}
                isError={!!isError}
            />
        );
    }

    displayModal() {
        var modalProps = store.modalProps || {};

        return (
            <ViewBlocker
                onBlockerClick={() => {
                    store.modalHide();
                }}
            >
                <Modal
                    content={store.modal()}
                    {...modalProps}
                />
            </ViewBlocker>
        );
    }

    viewRoadBlock() {
        var pathname = this.props.history.location.pathname,
            excludeUrls = [
                '/account/confirm/.*/',
                '/account/delete/.*/',
            ];

        var index = _.findIndex(excludeUrls, url => {
            var re = new RegExp(url);
            if (pathname.match(re)) {
                return true;
            }
        });

        if (index > -1) {
            return false;
        }

        return true;
    }

    getView() {
        if (store.show404) {
            return (<h1>{getWords('error:ui:404')}</h1>);
        }

        if (store.roadBlock && this.viewRoadBlock()) {
            return store.roadBlock();
        }
        
        return this.props.children;
    }

    render() {
        if (store.appLoading) {
            return (
                <div className='app-loading'>
                    <div className="app-loading__reveal"></div>
                    <h1 className='logo'>Kulinarian</h1>
                    <PreLoader />
                </div>
            );
        }

        return (
            <div
                ref={this.appContainerRef}
                className='app-container'
            >
                {store.appWaiting
                    ? <ViewBlocker
                        className='appwaiting'
                    >
                        <PreLoader className='white' />
                    </ViewBlocker>
                    : null
                }

                {store.alertBar
                    ? this.displayAlertBar()
                    : null
                }
                
                {store.errorBar
                    ? this.displayAlertBar(true)
                    : null
                }

                {store.modal
                    ? this.displayModal()
                    : null
                }

                {store.enableTopNav && <Topnav
                    history={this.props.history}
                />}

                <div className="app-view">
                    {this.getView()}
                </div>

                {store.enableFooter && <Footer />}
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default observer(App);