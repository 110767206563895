import _ from 'lodash';
import {decorate, observable, configure, action} from 'mobx';

configure({enforceActions: 'always'});

class FormStore {

    constructor() {
        this.tabIndex = 0;
    }

    setUp(formId) {
        if (_.get(this.formErrors, formId)) {
            delete this.formErrors[formId];
        }
    }

    tearDown(formId) {
        if (_.get(this.formErrors, formId)) {
            delete this.formErrors[formId];
        }

        if (_.get(this.inputValues, formId)) {
            delete this.inputValues[formId];
        }

        this.tabIndex = 0;
        this.tabIndexes = null;
    }

    getTabIndex(elementId) {
        if (!this.tabIndexes) {
            this.tabIndexes = {};
        }

        if (!_.get(this.tabIndexes, elementId)) {
            this.tabIndex += 1;
            this.tabIndexes[elementId] = this.tabIndex;
        }

        return this.tabIndexes[elementId];
    }

    getInputValues(formId) {
        return this.inputValues[formId];
    }

    updateInputError(formId, name, error) {
        if (this.inputValues[formId]) {
            this.inputValues[formId][name].error = error;
        }
    }

    setInputValues(formId, data) {
        if (!_.get(this, 'inputValues')) {
            this.inputValues = {};
        }
        this.inputValues[formId] = data;
    }

    setFormErrorMessage(formId, message) {
        if (!_.get(this, 'formErrors')) {
            this.formErrors = {};
        }
        this.formErrors[formId] = message;
    }

    updateInputValue(formId, name, value) {
        if (this.inputValues[formId]) {
            this.inputValues[formId][name].value = value;
        }
    }
    
    getInputValue(formId, name) {
        return this.inputValues[formId][name].value;
    }
}

decorate(FormStore, {
    formErrors: observable,
    inputValues: observable,
    tabIndexes: observable,

    getInputValue: action,
    getInputValues: action,
    getTabIndex: action,
    setFormErrorMessage: action,
    setInputValues: action,
    setUp: action,
    tearDown: action,
    updateInputValue: action,
    updateInputError: action,
});

export default new FormStore();