import _ from 'lodash';
import {decorate, configure, action} from 'mobx';

configure({enforceActions: 'always'});

class PubSub {

    constructor() {
        this.topics = {};
    }

    cancelEvent(topic, eventId) {
        if (_.get(this.topics, topic, eventId)) {
            delete this.topics[topic][eventId];
        }
    }

    publish(topic, options) {
        if (!_.get(this.topics, topic)) {
            return false;
        }

        _.forEach(this.topics[topic], callback => {
            callback(options);
        });

        delete this.topics[topic];
    }

    subscribe(topic, eventId, callback) {
        if (!_.get(this.topics, topic)) {
            this.topics[topic] = {};
        }

        this.topics[topic][eventId] = callback;
    }
}

decorate(PubSub, {
    
    cancelEvent: action,
    publish: action,
    subscribe: action
});

export default new PubSub();