import { hasGroup } from 'js/permissions';
import NotFound from 'js/components/notfound';
import React, { Component } from 'react';

const Authorization = (allowedGroups) =>
    (WrappedComponent) => class WithAuthorization extends Component {
        /**
         * Followed this tutorial;
         * https://blog.vcarl.com/role-based-authorization-react/
         */
        render() {
            if (hasGroup(allowedGroups)) {
                return <WrappedComponent {...this.props} />;
            }
            
            return <NotFound />;
        }
    };

export default Authorization;