import { observer } from 'mobx-react';
import appStore from 'js/stores/App';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AlertBar extends Component {

    render() {
        return (
            <div className={
                `alertbar ${this.props.isError ? 'alertbar--error' : ''}`
            }>
                <div className='alertbar__content'>
                    {this.props.content}
                </div>
                <div className='alertbar__btnwrap'>
                    <button
                        className='closethis alertbar__close'
                        onClick={e => {
                            e.preventDefault();
                            appStore.alertBarHide();
                        }}
                    >
                        &times;
                    </button>
                </div>
            </div>
        );
    }
}

AlertBar.propTypes = {
    content: PropTypes.string.isRequired,
    isError: PropTypes.bool.isRequired,
};

export default observer(AlertBar);