import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { parseurl } from 'js/utilities/parseurl';
import { request } from 'js/request';
import appStore from 'js/stores/App';
import PreLoader from 'js/components/preloader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import userStore from 'js/stores/User';


class AccountConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmed: false,
            expired: false,
            loading: true,
            message: null,
        };
    }

    componentDidMount() {
        if (this.props.message) {
            this.setState({
                message: this.props.message,
                loading: false
            });
        }
        else if (this.props.accountDelete) {
            this.setState({
                loading: false
            });
        }
        else {
            this.confirmAccount();
        }
    }

    confirmAccount(method) {
        var url = parseurl(
            window.apiurls.accountConfirm,
            {token: this.props.match.params.token}
        );

        method = method || 'GET';

        if (method !== 'GET') {
            this.setState({
                message: null,
                loading: true
            });
        }

        request({
            method: method,
            url: url
        }, (response, error) => {
            if (response && this.props.accountDelete && method === 'DELETE') {
                appStore.alertBarShow(response.message);
                userStore.unsetUser();
                this.props.history.push('/');
            }
            else if (response) {
                this.setState({
                    confirmed: true,
                    message: response.message,
                    loading: false
                });
            }
            else if (_.get(error, 'response.data.data.token_expired')) {
                this.setState({
                    expired: true,
                    loading: false
                });
            }
            else if (_.get(error, 'response.data.message')) {
                this.setState({
                    message: error.response.data.message,
                    loading: false
                });
            }
        });
    }

    updateAccountToken() {
        this.confirmAccount('PATCH');
    }
    
    deleteAccount() {
        this.confirmAccount('DELETE');
    }

    viewUpdateToken() {
        return (
            <div className='content'>
                <h1>{
                    getWords('registration:confirmation:expiredHeadline')
                }</h1>
                <div dangerouslySetInnerHTML={{
                    __html: getWords('registration:confirmation:expired')
                }}></div>
                <div>
                    <button
                        id='sendaccountconfirm'
                        onClick={() => {
                            this.updateAccountToken();
                        }}
                    >
                        {getWords('registration:confirmation:expiredButton')}
                    </button>
                </div>
            </div>
        );
    }

    viewResendEmail() {
        return (
            <React.Fragment>
                <h4>{getWords(
                    'registration:confirmation:resendTitle'
                )}</h4>
                <p>
                    {getWords(
                        'registration:confirmation:resendDescription'
                    )}
                </p>
                <div>
                    <button
                        id='sendaccountconfirm'
                        className='btn__md btn__tertiary'
                        onClick={() => {
                            this.updateAccountToken();
                        }}
                    >
                        {getWords(
                            'registration:confirmation:resendButton'
                        )}
                    </button>
                </div>
            </React.Fragment>
        );
    }

    viewAccountConfirm() {
        if (this.state.message) {
            return (
                <div className='content'>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.state.message
                        }}
                    ></div>
                    {this.state.confirmed
                        ? null
                        : this.viewResendEmail() 
                    }
                </div>
            );
        }
        else if (this.state.expired) {
            return this.viewUpdateToken();
        }

        return (<React.Fragment />);
    }

    viewAccountDelete() {
        if (this.state.message) {
            return (
                <div
                    className='content'
                    dangerouslySetInnerHTML={{
                        __html: this.state.message
                    }}
                ></div>
            );
        }
        else if (this.state.expired) {
            return this.viewUpdateToken();
        }
        else {
            return (
                <div className='content'>
                    <h1>{getWords('registration:accountDelete:title')}</h1>
                    <p>{getWords('registration:accountDelete:warning')}</p>
                    <button
                        id='accountdelete'
                        className='btn__danger'
                        onClick={() => {
                            this.deleteAccount();
                        }}
                    >{getWords('registration:accountDelete:button')}</button>
                </div>
            );
        }
    }

    render() {
        if (this.state.loading) {
            return (<PreLoader/>);
        }

        if (this.props.accountDelete) {
            return this.viewAccountDelete();
        }
        else {
            return this.viewAccountConfirm();
        }
    }
}

AccountConfirm.propTypes = {
    accountDelete: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    
    message: PropTypes.string,
};

export default AccountConfirm;