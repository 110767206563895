import _ from 'lodash';
import {decorate, observable, configure, action} from 'mobx';
import { getConfig } from 'js/utilities/getconfig';

configure({enforceActions: 'always'});

class RecipeSearchStore {

    /**
     * Search state should be in the shape of;
     * 
     * state = {
     *  search: {
     *      search: chicken,
     *  },
     *  search_ingredients: {
     *      rs_i_0: butter,
     *      rs_i_1: carrots,
     *  }
     * }
     * @param {string} name name of input (can be multiple inputs)
     * @param {string} key id of value within named inputs
     * @param {string} value 
     */
    setSearchState(name, key, value) {
        if (!this.searchState) {
            this.searchState = {};
        }

        if (!_.get(this.searchState, name)) {
            this.searchState[name] = {};
        }

        this.searchState[name][key] = value;
    }
    
    /**
     * Remove search attribute from state
     * @param {string} name name of input
     * @param {string} key id of value within named inputs
     */
    deleteSearchState(name, key) {
        if (_.get(this.searchState, `${name}.${key}`)) {
            delete this.searchState[name][key];
        }
    }
    
    /**
     * Remove all search attributes from state
     */
    purgeSearchState() {
        this.searchState = {};
    }

    getSearchState() {
        var delimiter = getConfig('SEARCH_KEYWORD_DELIMITER');
        var search = {};
        _.forEach(this.searchState, (filter, name) => {
            if (name === 'search') {
                search[name] = filter[name];
            }
            else {
                search[name] = _.values(filter).join(delimiter);
            }

            if (_.startsWith(name, 'search_tags')) {
                var tags = (
                    filter[name].split && filter[name].split(',')
                ) || [];
                tags = _.map(tags, tag => _.trim(tag));
                search[name] = _.values(tags).join(delimiter);
            }
        });

        _.forEach(search, (value, key) => {
            if (!value) {
                delete search[key];
            }
        });

        return search;
    }
}

decorate(RecipeSearchStore, {
    searchState: observable,
    searchFilter: observable,

    deleteSearchState: action,
    getSearchState: action,
    purgeSearchState: action,
    setSearchState: action,
});

export default new RecipeSearchStore();