import React, { Component } from 'react';
import store from 'js/stores/App';


class NotFound extends Component {

    componentDidMount() {
        store.set404();
    }

    render() {
        return (
            <div>
                <h1>404: Not Found</h1>
            </div>
        );
    }
}

export default NotFound;