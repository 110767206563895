import _ from 'lodash';
import inputStore from 'js/components/form/inputs/inputStore';
import { getWords } from 'js/utilities/getwords';
import appStore from 'js/stores/App';

export default {

    onMountHandleLabels() {
        if (!this.props.defaultValue) {
            return;
        }

        if (this.props.hideLabel) {
            this.hideLabel({
                target: this[this.props.name]
            }, true);
        }
        else {
            this.moveLabel({
                target: this[this.props.name]
            }, true);
        }
    },

    onFocus(e) {
        if (this.props.hideLabel) {
            this.hideLabel(e, true);
        }
        else {
            this.moveLabel(e, true);
        }
        this.updateBorder(e, true);
    },

    onBlur(e) {
        if (this.props.hideLabel) {
            this.hideLabel(e, false);
        }
        else {
            this.moveLabel(e, false);
        }
        this.updateBorder(e, false);
    },

    hideLabel(e, show) {
        var parentNode = _.get(e, 'target.parentNode');

        if (!parentNode) {
            return null;
        }

        var labelNode = _.find(parentNode.children, (e) => {
            return (e.tagName === 'LABEL'
            && e.className.includes(inputStore.labelClass));
        });

        if (!labelNode) {
            return null;
        }
        
        if (!labelNode.className.includes('--hide') && show) {
            labelNode.className = `${inputStore.labelClass} ${
                inputStore.labelClass}--hide`;
        }
        else if (!e.target.value
            && labelNode.className.includes('--hide')
            && !show
        ) {
            labelNode.className = inputStore.labelClass;
        }
    },
    
    moveLabel(e, up) {
        var parentNode = _.get(e, 'target.parentNode');
        
        if (!parentNode) {
            return null;
        }

        var labelNode = _.find(parentNode.children, (e) => {
            return (e.tagName === 'LABEL'
            && e.className.includes(inputStore.labelClass));
        });

        if (!labelNode) {
            return null;
        }
        
        if (!labelNode.className.includes('--move') && up) {
            labelNode.className = `${inputStore.labelClass} ${
                inputStore.labelClass}--move`;
        }
        else if (!e.target.value
            && labelNode.className.includes('--move')
            && !up
        ) {
            labelNode.className = inputStore.labelClass;
        }
    },

    updateBorder(e, focus) {
        if (!e.target.parentNode) {
            return null;
        }

        var fieldNode = e.target.parentNode.parentNode;
        var className = fieldNode.className;

        if (focus && !className.includes('--focus')) {
            className = className.replace(
                `${inputStore.wrapClass}`,
                `${inputStore.wrapClass} ${inputStore.wrapClass}--focus`
            );
        }
        else if (!focus && className.includes('--focus')) {
            className = className.replace(
                `${inputStore.wrapClass} ${inputStore.wrapClass}--focus`,
                `${inputStore.wrapClass}`
            );
        }

        className = className.replace(/\s+/, ' ');
        fieldNode.className = className;
    },

    getErrorText(state) {
        return (state && state.errorText) || this.props.errorText;
    },

    getFieldWrapClass(state) {
        var className = inputStore.wrapClass;

        if (this.getErrorText(state)) {
            className = `${className} field--error`;
        }

        if (this.props.noBorder) {
            className = `${className} field--noborder`;
        }
            
        return className;
    },

    getStylingClassName(className) {
        var validStyles = [
            'filter',
            'short'
        ];

        if (_.indexOf(validStyles, this.props.styling) > -1) {
            return `${className} field__style--${this.props.styling}`;
        }

        return className;
    },

    getInputValue() {
        return this.state.inputValue || this.props.inputValue;
    },

    onInputSave() {
        if (!this.getInputValue()) {
            this.setState({
                errorText: getWords('error:1006'),
            });
            return;
        }

        this.props.onInputSave(
            this.getInputValue(),
            (message, error) => {
                if (message) {
                    appStore.alertBarShow(message);
                }
                else if (error) {
                    this.setState({
                        errorText: error,
                    });
                }
            }
        );
    }
};