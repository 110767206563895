import {decorate, configure, action} from 'mobx';
import analytics from 'js/utilities/analytics';
import htmlMeta from 'js/utilities/htmlMeta';

configure({enforceActions: 'always'});

class ViewStore {

    constructor() {
        this.pathname = null;
        this.search = null;
    }

    onUpdate(routeProps, onRoutesInit) {
        if (routeProps.history.location.pathname !== this.pathname
            || routeProps.history.location.search !== this.search) {
            this.pathname = routeProps.history.location.pathname;
            this.search = routeProps.history.location.search;
            this.doUpdate(routeProps, onRoutesInit);
        }
    }

    doUpdate(routeProps, onRoutesInit) {
        analytics.pageView(routeProps);

        if (!onRoutesInit) {
            htmlMeta.reset();
        }
    }
}

decorate(ViewStore, {
    onUpdate: action,
});

export default new ViewStore();