import PropTypes from 'prop-types';

export default {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    fieldRef: PropTypes.object.isRequired,
    
    autocomplete: PropTypes.bool,
    className: PropTypes.string,
    errorText: PropTypes.string,
    leadingIcon: PropTypes.object,
    hideLabel: PropTypes.bool,
    noBorder: PropTypes.bool,
    noLabel: PropTypes.bool,
    onChange: PropTypes.func,
    onInputSave: PropTypes.func,
    required: PropTypes.bool,
    styleInput: PropTypes.object,
    styleWrap: PropTypes.object,
    styling: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])
};