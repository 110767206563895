import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SvgIndex extends Component {

    render() {
        return (
            <svg
                className={this.props.className}
                type='image/svg+xml'
                data-svgid={this.props.svgId}
            >
                <use xlinkHref={`#${this.props.svgId}`} />
            </svg>
        );
    }
}

SvgIndex.propTypes = {
    svgId: PropTypes.string.isRequired,

    className: PropTypes.string,
};

export default SvgIndex;