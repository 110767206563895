import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch() {
        this.setState({
            hasError: true
        });
    }

    render() {
        if(this.state.hasError) {
            return (<div>Something went wrong</div>);
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.object.isRequired,
};

export default ErrorBoundary;