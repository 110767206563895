import _ from 'lodash';
import userStore from 'js/stores/User';

function hasPerm(user, perm, item) {
    var itemUsername = getItemUserUsername(item);

    if (!itemUsername) {
        return false;
    }

    user.username = _.toLower(user.username);
    itemUsername = _.toLower(itemUsername);

    // dynamically call Perms class method
    var perms = new Perms();
    return perms[perm](user, itemUsername, item);
}

function getItemUserUsername(item) {
    var username = _.get(item, 'user.username');

    return username;
}

class Perms {

    edit(user, itemUsername) {
        return user.username === itemUsername;
    }
}

function hasGroup(allowedGroups) {
    if (!userStore.username) {
        return false;
    }

    if (_.isString(allowedGroups)) {
        allowedGroups = [allowedGroups];
    }

    var userGroups = _.map(userStore.groups, group => group.name);

    return _.isEqual(
        _.intersection(userGroups, allowedGroups),
        allowedGroups
    );
}

export {
    hasGroup,
    hasPerm,
};