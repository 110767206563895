import AppView from 'js/AppView';
import asyncComponent from 'js/AsyncComponent';
import historyStore from 'js/stores/History';
import React, { Component } from 'react';
import store from 'js/stores/App';
import viewStore from 'js/stores/View';
import NotFound from 'js/components/notfound';
import {
    Route,
    Switch
} from 'react-router-dom';
import Authorization from 'js/Authorization';

const AsyncAccountConfirm = asyncComponent(
    () => import('js/components/accountconfirm'));
const AsyncChefProfile = asyncComponent(
    () => import('js/components/chefprofile'));
const AsyncChefProfilePublic = asyncComponent(
    () => import('js/components/chefprofile/public'));
const AsyncContent = asyncComponent(
    () => import('js/components/content'));
const AsyncBlogPost = asyncComponent(
    () => import('js/components/blog/post'));
const AsyncBlogHome = asyncComponent(
    () => import('js/components/blog'));
const AsyncForgotPassword = asyncComponent(
    () => import('js/components/forgotpassword'));
const AsyncHome = asyncComponent(
    () => import('js/components/home'));
const AsyncMenuDetail = asyncComponent(
    () => import('js/components/menudetail'));
const AsyncMenuList = asyncComponent(
    () => import('js/components/menulist'));
const AsyncRecipeBuilder = asyncComponent(
    () => import('js/components/recipebuilder'));
const AsyncRecipeDetail = asyncComponent(
    () => import('js/components/recipedetail'));
const AsyncRecipeEmbed = asyncComponent(
    () => import('js/components/recipeembed'));
const AsyncRecipeList = asyncComponent(
    () => import('js/components/recipelist'));
const AsyncFlavorPairings = asyncComponent(
    () => import('js/components/flavorpairings'));
const AsyncFlavorPairingsSearch = asyncComponent(
    () => import('js/components/flavorpairings/search'));
const AsyncFlavorPairingsRecipes = asyncComponent(
    () => import('js/components/flavorpairings/recipes'));
const AsyncSignIn = asyncComponent(
    () => import('js/components/signin'));
const AsyncSignUp = asyncComponent(
    () => import('js/components/signup'));
const AsyncAdminUi = asyncComponent(
    () => import('js/components/adminui'));
const AsyncAdminUiLemmas = asyncComponent(
    () => import('js/components/adminui/lemmas/'));
const AsyncAdminUiWikipages = asyncComponent(
    () => import('js/components/adminui/wikipages/'));
const AsyncEmailUnsubscribe = asyncComponent(
    () => import('js/components/email/unsubscribe'));

const RoleAdmin = Authorization(window.userGroups.admin);

class Routes extends Component {

    constructor() {
        super();
        this.onRoutesInit = true;
    }
    
    render() {
        return (
            <Route render={(routeProps) => {
                store.show404 && store.unSet404();
                store.roadBlock && store.unSetRoadBlock();
                historyStore.setHistory(routeProps.history);
                store.setRouteProps(routeProps);
                viewStore.onUpdate(routeProps, this.onRoutesInit);
                this.onRoutesInit = false;

                return (
                    <AppView
                        history={routeProps}
                    >
                        <Switch>
                            <Route exact
                                path='/'
                                component={AsyncHome}
                            />
                            <Route
                                path='/chef/profile/'
                                component={AsyncChefProfile}
                            />
                            <Route
                                // key forces re-render of component
                                key='create-recipe'
                                path='/recipe/upload'
                                render={props => <AsyncRecipeBuilder
                                    recipeEdit={false}
                                    {...props} 
                                />}
                            />
                            <Route
                                // key forces re-render of component
                                key='edit-recipe'
                                path='/recipes/:slug/edit/'
                                render={props => <AsyncRecipeBuilder
                                    recipeEdit={true}
                                    {...props} 
                                />}
                            />
                            <Route
                                path='/recipes/:slug'
                                component={AsyncRecipeDetail}
                            />
                            <Route
                                path='/embed/recipes/:slug'
                                component={props => {
                                    store.setEnableTopNav(false);
                                    store.setEnableFooter(false);
                                    return <AsyncRecipeEmbed {...props} />;
                                }}
                            />
                            <Route
                                path='/recipes/'
                                component={AsyncRecipeList}
                            />
                            <Route
                                path='/flavor-pairings/recipes/:slug(.*)'
                                component={AsyncFlavorPairingsRecipes}
                            />
                            <Route
                                path='/flavor-pairings/search'
                                component={AsyncFlavorPairingsSearch}
                            />
                            <Route
                                path='/flavor-pairings/:ingredients'
                                component={AsyncFlavorPairingsSearch}
                            />
                            <Route
                                path='/flavor-pairings'
                                component={AsyncFlavorPairings}
                            />
                            <Route
                                path='/chef/:slug'
                                component={AsyncChefProfilePublic}
                            />
                            <Route
                                path='/menus/:slug'
                                component={AsyncMenuDetail}
                            />
                            <Route
                                path='/menus/'
                                component={AsyncMenuList}
                            />
                            <Route
                                path='/account/confirm/:token'
                                render={props => <AsyncAccountConfirm
                                    accountDelete={false}
                                    {...props} 
                                />}
                            />
                            <Route
                                path='/account/delete/:token'
                                render={props => <AsyncAccountConfirm
                                    accountDelete={true}
                                    {...props} 
                                />}
                            />
                            <Route
                                path='/signin/'
                                component={props => {
                                    return <AsyncSignIn
                                        redirect='/recipes'
                                        updateHtmlMeta={true}
                                        {...props}
                                    />;
                                }}
                            />
                            <Route
                                path='/login/'
                                component={props => {
                                    return <AsyncSignIn
                                        redirect='/recipes'
                                        updateHtmlMeta={true}
                                        {...props}
                                    />;
                                }}
                            />
                            <Route
                                path='/signup/'
                                component={props => {
                                    return <AsyncSignUp
                                        updateHtmlMeta={true}
                                        {...props}
                                    />;
                                }}
                            />
                            <Route
                                path='/forgotpassword/'
                                component={AsyncForgotPassword}
                            />
                            <Route
                                path='/account/forgotpassword/:token'
                                component={props => <AsyncForgotPassword
                                    passwordReset={true}
                                    {...props}
                                />}
                            />
                            <Route
                                path='/aboutus'
                                component={props => <AsyncContent
                                    slug={'about-kulinarian'}
                                    {...props}
                                />}
                            />
                            <Route
                                path='/termsofservice'
                                component={props => <AsyncContent
                                    slug={'terms-service-agreement'}
                                    {...props}
                                />}
                            />
                            <Route
                                path='/contact'
                                component={props => <AsyncContent
                                    slug={'contact-us'}
                                    {...props}
                                />}
                            />
                            <Route
                                path='/privacypolicy'
                                component={props => <AsyncContent
                                    slug={'privacy-policy'}
                                    {...props}
                                />}
                            />
                            <Route
                                path='/help/:slug'
                                component={props => {
                                    return <AsyncContent
                                        slug={props.match.params.slug}
                                        {...props}
                                    />;
                                }}
                            />
                            <Route
                                path='/help'
                                component={props => <AsyncContent
                                    slug={'help'}
                                    {...props}
                                />}
                            />
                            <Route
                                path='/info/:slug'
                                component={props => {
                                    return <AsyncContent
                                        slug={props.match.params.slug}
                                        {...props}
                                    />;
                                }}
                            />
                            <Route
                                path='/emails/unsubscribe/:token'
                                component={AsyncEmailUnsubscribe}
                            />
                            <Route
                                path='/blog/:slug'
                                component={AsyncBlogPost}
                            />
                            <Route
                                path='/blog/'
                                component={AsyncBlogHome}
                            />
                            <Route
                                path='/adminui/lemmas/'
                                component={RoleAdmin(AsyncAdminUiLemmas)}
                            />
                            <Route
                                path='/adminui/wikipages/'
                                component={RoleAdmin(AsyncAdminUiWikipages)}
                            />
                            <Route
                                path='/adminui/'
                                component={RoleAdmin(AsyncAdminUi)}
                            />
                            <Route
                                path='*'
                                component={NotFound}
                            />
                        </Switch>
                    </AppView>
                );
            }} />
        );
    }
}

export default Routes;
