import { getWords } from 'js/utilities/getwords';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import React, { Component } from 'react';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.cookieKeyCookiePolicy = 'cookiepolicyaccepted';
        this.state = {
            showCookiePolicy: false,
        };
    }

    componentDidMount() {
        if (!Cookies.get(this.cookieKeyCookiePolicy)) {
            this.setState({
                showCookiePolicy: true,
            });
        }
    }

    acceptCookiePolicy() {
        Cookies.set(this.cookieKeyCookiePolicy, 1, {expires: 365});
        this.setState({
            showCookiePolicy: false,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className='content footer'
                >
                    <div className='footer__linkgrp'>
                        <NavLink
                            to='/help/'
                        >
                            {getWords('footer:links:help')}
                        </NavLink>
                        <NavLink
                            to='/signin/'
                        >
                            {getWords('footer:links:signin')}
                        </NavLink>
                        <NavLink
                            to='/signup/'
                        >
                            {getWords('footer:links:register')}
                        </NavLink>
                        <NavLink
                            to='/aboutus/'
                        >
                            {getWords('footer:links:aboutUs')}
                        </NavLink>
                    </div>
                    <div className='footer__linkgrp'>
                        <p>
                            &copy; 2012 - {
                                new Date().getFullYear()} Kulinarian.com
                        </p>
                        <NavLink
                            to='/termsofservice/'
                        >
                            {getWords('footer:links:terms')}
                        </NavLink>
                        <NavLink
                            to='/privacypolicy/'
                        >
                            {getWords('footer:links:privacyPolicy')}
                        </NavLink>
                        <NavLink
                            to='/privacypolicy/#cookies'
                        >
                            {getWords('footer:links:cookies')}
                        </NavLink>
                    </div>
                    <div className='footer__linkgrp'>
                        <NavLink
                            to='/contact/'
                        >
                            {getWords('footer:links:contact')}
                        </NavLink>
                        <a href='/blog/'>
                            {getWords('footer:links:blog')}
                        </a>
                        <a href='http://madewithloveinbaltimore.org/'
                            dangerouslySetInnerHTML={{
                                __html: getWords('footer:links:madeWithLove')
                            }}
                        ></a>
                    </div>
                </div>

                {this.state.showCookiePolicy
                    && <div className='footer__cp'>
                        <p dangerouslySetInnerHTML={{
                            __html: getWords('footer:cookieNotice:description')
                        }}></p>
                        <button
                            className='btn__md footer__cpbtn'
                            onClick={() => {
                                this.acceptCookiePolicy();
                            }}
                        >
                            {getWords('footer:cookieNotice:buttonText')}
                        </button>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default observer(Footer);