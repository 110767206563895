import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import Chip from 'js/components/chip';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UserChip extends Component {

    render() {
        const compiled = _.template(getWords('recipe:user:avatarAltText'));
        const altText = compiled({
            'username': this.props.username
        });
    
        return (
            <Chip
                background='transparent'
                image={this.props.avatar}
                highlightText={this.props.highlightText}
                hideText={this.props.hideText}
                imageAltText={altText}
                linkTo={`/chef/${this.props.username}`}
                maxWidth={this.props.maxWidth}
                text={this.props.username}
                type={this.props.type || 'link'}
                size={this.props.size}
            />
        );
    }
}

UserChip.propTypes = {
    avatar: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    
    highlightText: PropTypes.bool,
    maxWidth: PropTypes.string,
    hideText: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
};

export default UserChip;