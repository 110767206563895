import _ from 'lodash';
import {decorate, observable, configure, action} from 'mobx';
import { getConfig } from 'js/utilities/getconfig';
import FilterItem from 'js/components/filters/filteritem';

configure({enforceActions: 'always'});

class FilterArray {

    constructor() {
        this.filters = {};
    }

    purge() {
        this.filters = {};
        return this;
    }

    deleteFilter(filterSet) {
        if (_.get(this.filters, filterSet)) {
            delete this.filters[filterSet];
        }

        return this;
    }

    /**
     * Add or update the `this.filters` object with the given
     * filterItem. Only one key/value pair for a given
     * filterSet can be present in the `this.filters` object
     * @param {filterItem} filter 
     */
    updateFilterSet(filter) {
        if (!_.get(this.filters, filter.filterSet)) {
            this.filters[filter.filterSet] = {};
        }

        var key = filter.filterKey || filter.key;
        var value = filter.value;

        this.filters[filter.filterSet] = {
            key: key,
            value: value,
        };

        return this;
    }

    /**
     * Return the key for a filterSet
     * @param {string} filterSet input name
     */
    getFilterSetKey(filterSet) {
        if (_.get(this.filters, filterSet)) {
            return this.filters[filterSet].key;
        }

        return null;
    }

    /**
     * Return the key for a filterSet
     * @param {string} filterSet input name
     */
    getFilterSetValue(filterSet) {
        if (_.get(this.filters, filterSet)) {
            return this.filters[filterSet].value;
        }

        return null;
    }

    /**
     * Get the key/value pairs for all `this.filters`
     */
    getFilterKeyValues() {
        var filters = _.values(this.filters),
            params = {};

        _.forEach(filters, f => {
            if (f.key && f.value) {
                params[f.key] = f.value;
            }
        });
        
        return params;
    }

    getFilter(filterSet) {
        if (_.get(this.filters, filterSet)) {
            return this.filters[filterSet];
        }

        return null;
    }
}

class FilterStore {

    init(component) {
        if (!this.filters) {
            this.filters = {};
        }

        this.filters[component] = new FilterArray();
    }

    getExternalFilters(component) {
        if (!_.get(this, 'externalFilterStack', component)) {
            return {};
        }

        return _.pickBy(this.filters[component].filters, filter => {
            return _.indexOf(
                this.externalFilterStack[component],
                filter.key
            ) > -1;
        });
    }

    /**
     * Set `external` filters which are not associated with 
     * filter inputs.
     * Each time `setExternalFilters` is called, delete all
     * existing `external` filters before populating new filters.
     * @param {string} component name of view/component
     * @param {object} filters 
     */
    setExternalFilters(component, filters) {
        if (!_.get(this.filters, component)) {
            this.init(component);
        }

        if (!_.get(this, 'externalFilterStack')) {
            this.externalFilterStack = {};
        }
        
        if (!_.get(this.externalFilterStack, component)) {
            this.externalFilterStack[component] = [];
        }

        // First, delete the previous filters in the externalFilterStack
        _.forEach(this.externalFilterStack[component], filter => {
            this.filters[component] = (this.filters[component]
                .deleteFilter(filter));
        });

        // Now add the newest filters to the stack
        this.externalFilterStack[component] = _.keys(filters);
        var externalFilters = getConfig('EXTERNAL_FILTER_KEYS');

        _.forEach(filters, (value, key) => {
            if (externalFilters.indexOf(key) > -1) {
                var item = new FilterItem({
                    filterSet: key,
                    key: key,
                    value: value,
                });
        
                this.filters[component] = this.filters[
                    component].updateFilterSet(item);
            }
        });

        this.pubFilters(component);
    }

    purgeFilters(component) {
        if (_.get(this.filters, component)) {
            delete this.filters[component];
        }
    }

    getFilters(component) {
        return this.filters[component];
    }

    setFilters(component, filterArray) {
        this.filters[component] = filterArray;
    }

    /**
     * Publish filters updates
     */
    pubFilters(component) {
        if (!_.get(this.pubSubFilter, component)) {
            return;
        }
        
        if (!_.get(this.filters, `${component}.filters`)) {
            this.pubSubFilter[component]();
            return;
        }
        if (_.isEmpty(this.filters[component].filters)) {
            this.pubSubFilter[component]();
            return;
        }

        this.pubSubFilter[component](this.filters[component]);

        /* Delete the sub to ensure the sub is not 
        called multiple times */
        delete this.pubSubFilter[component];
    }

    /**
     * Subscribe to filters updates.
     * The last call to `subFilters` over writes the
     * previous calls to `subFilters`, meaning only
     * one callback per pub.
     * @param {string} component subscriber id
     * @param {func} callback 
     */
    subFilters(component, callback) {
        if (!this.pubSubFilter) {
            this.pubSubFilter = {};
        }

        this.pubSubFilter[component] = callback;
    }

    setFormRef(ref) {
        this.formRef = ref;
    }

    submitForm() {
        this.formRef.current.handleSubmit();
    }

    setComponentPathName(component) {
        this.componentPathName = component;
    }
}

decorate(FilterStore, {
    componentPathName: observable,
    externalFilterStack: observable,
    filters: observable,
    formRef: observable,
    
    getExternalFilters: action,
    getFilters: action,
    init: action,
    purgeFilters: action,
    setComponentPathName: action,
    setExternalFilters: action,
    setFilters: action,
    setFormRef: action,
    submitForm: action,
});

export default new FilterStore();