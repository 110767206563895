
class FilterItem {

    constructor(args) {
        if (args) {
            this.key = args.key;
            this.value = args.value;
            this.filterSet = args.filterSet;
            this.filterKey = args.filterKey;
        }
        else {
            this.key = null;
            this.value = null;
            this.filterSet = null;
            this.filterKey = null;
        }
    }
}

export default FilterItem;