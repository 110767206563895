import 'typeface-lobster';
import 'typeface-open-sans';
import 'normalize.css';
import 'scss/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'js/App';
import {
    BrowserRouter
} from 'react-router-dom';

if (document.querySelector('#app-preload')) {
    document.querySelector('#app-preload').remove();
}

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
