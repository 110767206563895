import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import appStore from 'js/stores/App';
import feedStore from 'js/components/feed/store';
import flavorPairingsStore from 'js/components/flavorpairings/store';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import recipeListStore from 'js/components/recipelist/store';
import Search from 'js/components/recipesearch';
import Svg from 'js/components/svg';
import Usermenu from 'js/components/topnav/usermenu';
import userStore from 'js/stores/User';

class Topnav extends Component {

    constructor(props) {
        super(props);

        this.activeTabRef = React.createRef();
        this.tabs = {
            'flavor-pairings': React.createRef(),
            'recipes': React.createRef(),
            'recipe/upload': React.createRef(),
        };

        feedStore.subFeedPopulated('topNavTabs', () => {
            this.showActiveBar();
        });

        this.state = {
            route: this.getRoute(),
            expandMiddle: false,
        };
    }

    componentDidUpdate() {
        var route = this.getRoute();

        this.showActiveBar();
        
        if (route !== this.state.route) {
            this.setState({
                route: route
            });
        }
    }

    componentDidMount() {
        this.bindTabEvents();
        this.showActiveBar();
    }

    showActiveBar() {
        if (!this.activeTabRef.current) {
            return;
        }

        var route = this.getRoute(),
            routeKeys = _.keys(this.tabs);

        if (_.indexOf(routeKeys, route) > -1) {
            this.activeTabRef.current.style.display = 'block';
            this.moveActiveBar(this.tabs[route].current);
        }
        else {
            this.activeTabRef.current.style.display = 'none';
        }
    }

    moveActiveBar(element) {
        if (!_.isElement(element) || !this.activeTabRef.current) {
            return null;
        }

        if (window.innerWidth < window.uiBreakPoints.md) {
            this.activeTabRef.current.style.display = 'none';
        }
        else {
            var left = (element.parentElement.parentElement.offsetLeft
                + element.parentElement.offsetLeft);
            this.activeTabRef.current.style.left = `${left}px`;
            this.activeTabRef.current.style.width = `${
                element.offsetWidth}px`;
        }
    }

    bindTabEvents() {
        _.forEach(this.tabs, (tab) => {
            if (tab.current) {
                tab.current.addEventListener('click', (e) => {
                    this.toggleExpandMiddle(false);
                    this.moveActiveBar(e);
                });
            }
        });

        window.onresize = () => {
            if (this.activeTabRef.current) {
                this.activeTabRef.current.style.display = 'none';
            }
        };
    }

    getRoute() {
        return _.trim(this.props.history.location.pathname, '/');
    }

    toggleExpandMiddle(expand) {
        this.setState({
            expandMiddle: expand
        });
    }

    showSignIn() {
        appStore.modalShow(() => {
            return (<p
                dangerouslySetInnerHTML={{
                    __html: getWords('deprecate:signIn')
                }}
            ></p>);
        });
    }

    getMiddleGroupClassName() {
        var className = 'topnav__group topnav__group--middle';

        if (this.state.expandMiddle) {
            return `${className} topnav__group--middleopen`;
        }

        return className;
    }

    handleRecipesClick() {
        recipeListStore.resetView();
    }

    getUserMenu() {
        if (userStore.username) {
            return (
                <div
                    role='button'
                    className='btn__transparent usermenu__wrap'
                >
                    {<Usermenu
                        onClose={() => {
                            this.toggleExpandMiddle(false);
                        }}
                    />}
                </div>
            );
        }
        else {
            return (
                <button
                    onClick={() => {
                        this.showSignIn();
                    }}
                    className='btn__md topnav__signin'
                >
                    {getWords('nav:topNav:signInButton')}
                </button>
            );
        }
    }

    getLogoSvg() {
        var items = ['garlic', 'lemon', 'parsley'];
        return `svgs-logo-circle-${items[_.random(0, 2)]}`;
    }

    render() {
        return (
            <div className='topnav'>
                
                <div ref={this.activeTabRef} className='topnav__active'></div>
                
                <div className='topnav__group topnav__group--start'>
                    <NavLink
                        to='/'
                        className='topnav__link topnav__logo'
                        title={getWords('appName')}
                    >
                        <Svg svgId={this.getLogoSvg()} />
                        <h1 className='logo'>{getWords('appName')}</h1>
                    </NavLink>
                </div>

                <div className={this.getMiddleGroupClassName()}>
                    <div className='topnav__user'>
                        {this.getUserMenu()}
                    </div>
                    <Search
                        className='topnav__search'
                        onExit={() => {
                            this.toggleExpandMiddle(false);
                        }}
                    />
                    <NavLink
                        to='/recipes/'
                        className='topnav__link'
                        onClick={this.handleRecipesClick}
                    >
                        <div
                            ref={this.tabs.recipes}
                            className='topnav__tab'>
                            {getWords('nav:topnav:recipes')}
                        </div>
                    </NavLink>
                    <NavLink
                        to='/recipe/upload/'
                        className='topnav__link'
                    >
                        <div
                            ref={this.tabs['recipe/upload']}
                            className='topnav__tab'
                        >
                            {getWords('nav:topnav:recipeBuilder')}
                        </div>
                    </NavLink>
                    <NavLink
                        to='/flavor-pairings'
                        className='topnav__link'
                        onClick={() => {
                            flavorPairingsStore.resetView();
                        }}
                    >
                        <div
                            ref={this.tabs['flavor-pairings']}
                            className='topnav__tab'>
                            {getWords('nav:topnav:flavorPairings')}
                        </div>
                    </NavLink>
                </div>

                <div className='topnav__group topnav__group--end'>
                    <button
                        className='btn__transparent topnav__hamburger'
                        onClick={() => {
                            this.toggleExpandMiddle(
                                this.state.expandMiddle ? false : true
                            );
                        }}
                    >
                        <Svg svgId='svgs-menu' />
                    </button>
                    <div className='topnav__user'>
                        {this.getUserMenu()}
                    </div>
                </div>
            </div>
        );
    }
}

Topnav.propTypes = {
    history: PropTypes.object.isRequired,
};

export default observer(Topnav);