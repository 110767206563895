import _ from 'lodash';
import {decorate, observable, configure, action} from 'mobx';
import ModalConfirm from 'js/components/modal/confirm';
import PubSub from 'js/stores/PubSub';
import React from 'react';

configure({enforceActions: 'always'});

class AppStore {

    constructor() {
        this.dependencies = [];
        this.topicAppLoaded = 'App.appLoading';
        this.setEnableTopNav(true);
        this.setEnableFooter(true);
    }

    setEnableTopNav(enable) {
        this.enableTopNav = enable;
    }

    setEnableFooter(enable) {
        this.enableFooter = enable;
    }

    updateAppWaiting(appWaiting) {
        this.appWaiting = appWaiting;
    }

    modalConfirm(data, callback) {
        this.modalShow(() => {
            return (
                <ModalConfirm
                    callback={callback}
                    confirmText={data.confirmText}
                    cancelText={data.cancelText}
                    description={data.description}
                    title={data.title}
                />
            );
        });
    }

    modalShow(modal, props) {
        this.modal = modal;
        this.modalProps = props;
    }

    modalHide() {
        this.modal = null;
    }

    alertBarShow(alertBar) {
        this.alertBar = alertBar;
        setTimeout(() => {
            this.alertBarHide();
        }, 4000);
    }

    alertBarHide() {
        this.alertBar = null;
        this.errorBar = null;
    }

    errorBarShow(errorBar) {
        this.errorBar = errorBar;
        setTimeout(() => {
            this.alertBarHide();
        // set longer timeout so people have
        // time to grab the error
        }, 8000);
    }

    escKeyPush(event) {
        if (!this.escKeyEvents) {
            this.escKeyEvents = [];
        }

        this.escKeyEvents.push(event);
    }

    escKeyClear() {
        this.escKeyEvents = null;
    }

    escKeyHook() {
        _.forEach(this.escKeyEvents, (event) => {
            event();
        });
        this.escKeyClear();
    }

    setAppLoading(loading) {
        this.appLoading = loading;
    }

    setRouteProps(routeProps) {
        this.routeProps = routeProps;
    }
    
    set404() {
        this.show404 = true;
    }

    unSet404() {
        this.show404 = false;
    }

    setAppContainerRef(ref) {
        this.appContainerRef = ref;
    }

    loadDependency(callback) {
        this.dependencies.push(true);
        callback();
    }

    dependencyLoaded() {
        this.dependencies.shift();

        if (this.dependencies.length === 0) {
            this.appLoading = false;
            PubSub.publish(this.topicAppLoaded);
        }
    }

    setRoadBlock(roadBlock) {
        this.roadBlock = roadBlock;
    }

    unSetRoadBlock() {
        this.roadBlock = null;
    }

    subAppLoaded(eventId, callback) {
        if (_.isUndefined(this.appLoading) || this.appLoading) {
            PubSub.subscribe(this.topicAppLoaded, eventId, callback);
            
            return;
        }

        callback();
    }
}

decorate(AppStore, {
    alertBar: observable,
    appContainerRef: observable,
    appLoading: observable,
    appWaiting: observable,
    enableTopNav: observable,
    enableFooter: observable,
    errorBar: observable,
    modal: observable,
    modalProps: observable,
    roadBlock: observable,
    routeProps: observable,
    show404: observable,

    alertBarHide: action,
    alertBarShow: action,
    dependencyLoaded: action,
    errorBarShow: action,
    escKeyClear: action,
    escKeyHook: action,
    escKeyPush: action,
    getRoadBlock: action,
    loadDependency: action,
    modalConfirm: action,
    modalHide: action,
    modalShow: action,
    set404: action,
    setAppContainerRef: action,
    setAppLoading: action,
    setEnableTopNav: action,
    setEnableFooter: action,
    setRoadBlock: action,
    setRouteProps: action,
    subAppLoaded: action,
    unSet404: action,
    unSetRoadBlock: action,
    updateAppWaiting: action,
});

export default new AppStore();