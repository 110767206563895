import {decorate, configure, action} from 'mobx';

configure({enforceActions: 'always'});

class HistoryStore {

    setHistory(history) {
        this.history = history;
    }
    
    push(location) {
        this.history.push(location);
    }

    go(n) {
        this.history.go(n);
    }

    replace(location) {
        this.history.replace(location);
    }

    replaceState(state) {
        var location = this.history.location;
        
        this.history.replace({
            pathname: location.pathname,
            search: location.search,
            state: state,
        });
    }
}

decorate(HistoryStore, {
    // history: observable, Do not set as observable, it causes errors

    flushOnPopState: action,
    onPopState: action,
    push: action,
    replace: action,
    replaceState: action,
    setHistory: action,
});

export default new HistoryStore();