import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PreLoader extends Component {

    render() {
        return (
            <div
                className={
                    `preloader-wrapper big active ${
                        this.props.className ? this.props.className : ''
                    }`
                }
            >
                <div className='spinner-layer spinner-color-only'>
                    <div className='circle-clipper left'>
                        <div className='circle'></div>
                    </div>
                    <div className='gap-patch'>
                        <div className='circle'></div>
                    </div>
                    <div className='circle-clipper right'>
                        <div className='circle'></div>
                    </div>
                </div>
            </div>
        );
    }
}

PreLoader.propTypes = {
    className: PropTypes.string,
};

export default PreLoader;