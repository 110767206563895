import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { observer } from 'mobx-react';
import { request } from 'js/request';
import { withRouter } from 'react-router-dom';
import appStore from 'js/stores/App';
import formStore from 'js/components/form/store';
import historyStore from 'js/stores/History';
import htmlMeta from 'js/utilities/htmlMeta';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import userStore from 'js/stores/User';

class SignInIndex extends Component {

    constructor(props) {
        super(props);
        this.formUserSigninId = 'usersignin';
    }

    componentDidMount() {
        if (this.props.updateHtmlMeta) {
            htmlMeta.update({
                title: getWords('meta:signin:title'),
                description: getWords('meta:signin:description')
            });
        }
    }

    handleSignIn(values) {
        appStore.updateAppWaiting(true);
        request({
            method: 'POST',
            url: window.apiurls.signIn,
            data: {
                username: values.username.value,
                password: values.password.value,
            }
        }, (response, error) => {
            appStore.updateAppWaiting(false);
            if (response) {
                userStore.setUser(response.data);
                formStore.setFormErrorMessage(
                    this.formUserSigninId,
                    null
                );
                appStore.modalHide();
                this.props.onSuccess && this.props.onSuccess();
                this.doRedirect();
            }
            else {
                formStore.setFormErrorMessage(
                    this.formUserSigninId,
                    _.get(error, 'response.data.message') || error.message
                );
            }
        });
    }

    doRedirect() {
        if (this.props.redirect) {
            historyStore.push({
                pathname: this.props.redirect
            });
        }
    }

    render() {
        return (
            <p
                dangerouslySetInnerHTML={{
                    __html: getWords('deprecate:signIn')
                }}
            ></p>
        );
    }
}

SignInIndex.propTypes = {
    description: PropTypes.string,
    inline: PropTypes.bool,
    onSuccess: PropTypes.func,
    redirect: PropTypes.string,
    updateHtmlMeta: PropTypes.bool,
};

export default observer(withRouter(SignInIndex));