import _ from 'lodash';
import AccountConfirm from 'js/components/accountconfirm';
import appStore from 'js/stores/App';
import appVersionHash from 'js/utilities/appVersionHash';
import axios from 'axios';
import Cookies from 'js-cookie';
import historyStore from 'js/stores/History';
import htmlMeta from 'js/utilities/htmlMeta';
import React from 'react';
import Signin from 'js/components/signin';
import userStore from 'js/stores/User';

function createQueryString(params) {
    // remove the url in params if it was passed as an option
    // to a given requests method
    if ('url' in params) {
        delete params.url;
    }
    params = Object.entries(params).map(
        ([key, val]) => {
            if(_.isArray(val)) {
                return _.map(val, (item) => `${key}=${item}`).join('&');
            }
            else {
                return `${key}=${val}`;
            }
        }
    ).join('&');

    return params;
}

/** 
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
URLSearchParams.prototype.toObject = function() {
    var query = {};
    
    this.forEach((value, key) => {
        query[key] = value;
    });
    
    return query;
};

function queryStringToObject(query) {
    if (_.isEmpty(query)) {
        return {};
    }

    query = formatQueryString(query);
    query = new URLSearchParams(query);
    return query.toObject();
}

function mergeQueryStrings(stringA, stringB) {
    stringA = formatQueryString(stringA);
    stringB = formatQueryString(stringB);
    var objectA = new URLSearchParams(stringA || '');
    var objectB = new URLSearchParams(stringB);
    var mergedStrings = _.merge(objectA.toObject(), objectB.toObject());
    var result = new URLSearchParams(mergedStrings);
    return result.toString();
}

function formatQueryString(query) {
    query = _.trim(query || '');
    query = _.trim(query, '?');
    query = _.trim(query, '&');

    return query;
}

function formData(targetFiles, find, key) {
    var formData = new FormData();

    _.map(targetFiles, (file) => {
        if (file.type.match(find)) {
            formData.append(key, file, file.name);
        }
    });

    return formData;
}

function handleAccountLocked(data) {
    if (_.has(data, 'data.account_confirmed') && !data.data.account_confirmed) {
        appStore.setRoadBlock(() => {
            return (<AccountConfirm
                accountDelete={false}
                history={historyStore.history}
                message={data.message}
                match={{
                    params: {token: data.data.email_validate}
                }}   
            />);
        });

        return null;    
    }

    appStore.setRoadBlock(() => {
        return (
            <div className='content'
                dangerouslySetInnerHTML={{
                    __html: data.message
                }}
            ></div>
        );
    });
}

function getCancelToken() {
    return axios.CancelToken.source();
}

function deleteRequestCancelQueueItem(key) {
    if (_.get(window.requestCancelQueue[key])) {
        delete window.requestCancelQueue[key];
    }
}

window.requestCancelQueue = {};

function request(options, callback) {
    var instance = {},
        params = '',
        method = _.get(options, 'method', 'GET'),
        signInOnError = _.get(options, 'signInOnError', false),
        signInOnErrorText = _.get(options, 'signInOnErrorText', '');

    instance.method = method;

    if (options.cancelId) {
        // If cancelToken already exists in the queue for the
        // given request.cancelId, then cancel the request
        // and replace the cancel queue request id
        if (_.get(window.requestCancelQueue, options.cancelId)) {
            window.requestCancelQueue[options.cancelId].cancel();
            deleteRequestCancelQueueItem(options.cancelId);
        }
        
        window.requestCancelQueue[
            options.cancelId] = axios.CancelToken.source();
        
        // need to check if CancelToken is undefined, this
        // was throwing errors in unit tests, not sure why
        if (_.get(window.requestCancelQueue, options.cancelId)) {
            instance.cancelToken = window.requestCancelQueue[
                options.cancelId].token;
        }
    }

    if (options.url) {
        instance.url = options.url;
    } else {
        throw new Error('request url is required');
    }

    if (method !== 'get') {
        instance.data = _.get(options, 'data', null);

        if (options.params) {
            params = createQueryString(options.params);
            if(params) {
                instance.url = `${instance.url}?${params}`;
            }
        }
    }

    instance.headers = {
        'X-CSRFToken': Cookies.get('csrftoken')
    };

    if (_.get(options, 'multipart', false)) {
        instance.headers['Content-Type'] = 'multipart/form-data';
    }
    else {
        instance.headers['Content-Type'] = 'application/json';
    }

    if (_.indexOf(window.showSignInPaths, window.location.pathname) > -1) {
        signInOnError = true;
    }

    axios(instance)
        .then(response => {
            deleteRequestCancelQueueItem(options.cancelId);
            if (options.updateHtmlMeta) {
                htmlMeta.update({
                    description: response.data.description,
                    image: response.data.image,
                    title: response.data.title,
                });
            }
            appVersionHash.check(response);
            callback(response.data);
        })
        .catch(response => {
            deleteRequestCancelQueueItem(options.cancelId);

            if (response.response
                    && _.indexOf([401, 440], response.response.status) > -1) {
                userStore.unsetUser();
                if (signInOnError) {
                    appStore.modalShow(() => {
                        return <Signin
                            onSuccess={() => {
                                // try making the original request again
                                request(options, callback);
                            }}
                            description={signInOnErrorText}
                        />;
                    });
                    return null;
                }
                callback(null, response);
                return null;
            }
            
            if (response.response && response.response.status === 404) {
                appStore.set404();
                callback(null, response);
                return null;
            }
            
            // 423 is account locked
            if (response.response && response.response.status === 423) {
                handleAccountLocked(response.response.data);
                callback(response.response.data);
                return null;
            }

            if (_.isError(response)) {
                callback(null, response);
                throw response;
            }

            callback(null, response);
        });
}

export {
    createQueryString,
    formatQueryString,
    formData,
    getCancelToken,
    mergeQueryStrings,
    queryStringToObject,
    request,
};