import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { hasPerm } from 'js/permissions';
import { request } from 'js/request';
import { decorate, observable, configure, action } from 'mobx';
import historyStore from 'js/stores/History';
import appStore from 'js/stores/App';
import React from 'react';
import UserChip from 'js/components/chefprofile/userChip';

configure({enforceActions: 'always'});

class UserStore {

    chip() {
        return (
            <UserChip
                avatar={this.avatar}
                username={this.username}
            />
        );
    }

    unsetUser() {
        this.avatar = null;
        this.description = null;
        this.id = null;
        this.username = null;
        this.groups = null;
    }

    setUserData(data) {
        _.forEach(data, (value, key) => {
            this[key] = value;
        });
    }

    setUser(user) {
        if (user) {
            this.setUserData(user);
        }
        else {
            this.unsetUser();
        }
    }

    setUsername(username) {
        this.username = username;
    }
    
    setAvatar(avatar) {
        this.avatar = avatar;
    }

    signOut() {
        request({
            method: 'POST',
            url: window.apiurls.signOut
        }, () => {
            this.unsetUser();
            historyStore.push({
                pathname: historyStore.history.location.pathname,
                search: historyStore.history.location.search,
            });
            appStore.alertBarShow(getWords('signOut:alert:success'));
        });
    }

    hasPerm(perm, item) {
        return hasPerm({
            username: this.username,
        }, perm, item);
    }
}

decorate(UserStore, {
    avatar: observable,
    description: observable,
    id: observable,
    username: observable,
    groups: observable,

    setAvatar: action,
    setUser: action,
    setUserData: action,
    setUsername: action,
    signOut: action,
    unsetUser: action,
});

export default new UserStore();