import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { observer } from 'mobx-react';
import inputProps from 'js/components/form/inputs/inputProps';
import inputStore from 'js/components/form/inputs/inputStore';
import InputUtils from 'js/components/form/inputs/inputUtils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class InputTextArea extends Component {

    constructor(props) {
        super(props);
        _.each(InputUtils, (item,key) => this[key] = _.isFunction(item)
            ? item.bind(this) : item
        );
        this.state = {
            inputValue: null,
            errorText: null,
        };
    }

    componentDidMount() {
        this.onMountHandleLabels();
    }

    getInputValue() {
        return this.state.inputValue || this.props.inputValue;
    }

    getClassName() {
        var className = `${this.getFieldWrapClass(this.state)} ${
            inputStore.wrapClass}--textarea`;

        if (this.props.height === 'small') {
            className = `${className} ${inputStore.wrapClass}--textareaSm`;
        }

        return className;
    }

    getTextareaClassName() {
        var className = `field__input field__input--textarea ${
            this.props.className || ''
        }`;

        if (this.props.height === 'small') {
            className = `${className} field__input--textareaSm`;
        }

        return className;
    }

    render() {
        return (
            <div className={this.getStylingClassName('form__field')}>
                <div
                    className={this.getClassName()}
                    style={this.props.styleWrap}
                    ref={this.props.fieldRef}
                >
                    {this.props.leadingIcon}
                    <div className={inputStore.fieldWrapClass}>
                        <label
                            className={inputStore.labelClass}
                            htmlFor={this.props.id}
                        >
                            {this.props.labelText}
                            {this.props.required
                                ? <span
                                    className={inputStore.inputRequiredClass}
                                >*</span>
                                : null
                            }
                        </label>
                        <textarea
                            id={this.props.id}
                            autoComplete={
                                !this.props.autocomplete ? 'off' : 'on'
                            }
                            aria-label={this.props.name}
                            aria-required={this.props.required || false}
                            className={this.getTextareaClassName()}
                            name={this.props.name}
                            onChange={e => {
                                this.props.onChange(e);
                                this.setState({
                                    errorText: null,
                                    inputValue: e.target.value,
                                });
                            }}
                            onBlur={(e) => {
                                this.onBlur(e);
                            }}
                            onFocus={(e) => {
                                this.onFocus(e);
                            }}
                            ref={element => {this[this.props.name] = element;}}
                            style={this.props.styleInput}
                            type={this.props.type || 'text'}
                            defaultValue={this.props.defaultValue || null}
                        />
                        
                        {this.getErrorText(this.state)
                            ? <div className={inputStore.fieldErrorClass}>
                                {this.getErrorText(this.state)}
                            </div>
                            : null
                        }                    
                    </div>
                </div>
                {this.props.onInputSave
                    ? <button
                        className='field__save'
                        onClick={e => {
                            e.preventDefault();
                            this.onInputSave();
                        }}
                    >
                        {getWords('form:input:save')}
                    </button>
                    : null
                }
            </div>
        );
    }
}

var componentProps = {
    name: PropTypes.string.isRequired,

    height: PropTypes.string
};

InputTextArea.propTypes = {...inputProps, ...componentProps};

export default observer(InputTextArea);
