import { observer } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from 'js/ErrorBoundary';
import React, { Component } from 'react';
import Routes from 'js/Routes';
import setup from 'js/setup';

setup();

class App extends Component {
    /*
    Using example from here
    https://kamranicus.com/posts/2018-07-26-react-router-custom-transition-ui
    */
    getUserConfirmation(dialogKey, callback) {    
        // use "message" as Symbol-based key
        const dialogTrigger = window[Symbol.for(dialogKey)];
    
        if (dialogTrigger) {
            // delegate to dialog and pass callback through
            return dialogTrigger(callback);
        }
    
        // Fallback to allowing navigation
        callback(true);
    }

    render() {
        return (
            <ErrorBoundary>
                <Router
                    getUserConfirmation={this.getUserConfirmation}
                >
                    <Routes />
                </Router>
            </ErrorBoundary>
        );
    }
}

export default observer(App);