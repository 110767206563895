/* global gtag */

import { getConfig } from 'js/utilities/getconfig';
import appStore from 'js/stores/App';

window.addEventListener('afterprint', () => {
    userEvent('print', 'engagement');
});

function pageView(routeProps) {
    appStore.subAppLoaded('analytics_page_view', () => {
        var uaId = window.location.pathname.match(/^\/embed\//)
            ? getConfig('EMBED_GOOGLE_ANALYTICS_ID')
            : getConfig('GOOGLE_ANALYTICS_ID');

        var location = routeProps.location;

        try {
            gtag('config', uaId, {
                page_path: `${location.pathname}${location.search}`,
                allow_ad_personalization_signals: false
            });
        }
        catch (e) {
            // do nothing
        }
    });
}

function userEvent(event, category, label, value) {
    try {
        gtag('event', event, {
            'event_category': category,
            'event_label': label,
            'value': value
        });
    }
    catch (e) {
        // do nothing
    }
}

export default {
    pageView,
    userEvent,
};