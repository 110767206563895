import { decorate, observable, configure, action } from 'mobx';
import { randomString } from 'js/utilities/randomstring';
import filterStore from 'js/components/filters/store';

configure({enforceActions: 'always'});

class FlavorPairingStore {

    resetView() {
        filterStore.purgeFilters(this.componentRoute);
        this.updateFilters({});
        this.updateFeedReloadKey();
        this.updateFiltersReloadKey();
    }

    setComponentRoute(name) {
        this.componentRoute = name;
    }

    setParentComponent(component) {
        this.parentComponent = component;
    }

    updateFeedReloadKey() {
        this.feedReloadKey = randomString(6);
    }

    updateFiltersReloadKey() {
        this.filtersReloadKey = randomString(6);
    }

    updateFilterInputs(inputs) {
        this.filterInputs = inputs;
    }

    updateFilters(filters) {
        this.filters = filters;
    }
}

decorate(FlavorPairingStore, {
    componentRoute: observable,
    feedReloadKey: observable,
    filterInputs: observable,
    filters: observable,
    filtersReloadKey: observable,
    parentComponent: observable,

    resetView: action,
    setComponentRoute: action,
    setParentComponent: action,
    updateFeedReloadKey: action,
    updateFilterInputs: action,
    updateFilters: action,
    updateFiltersReloadKey: action,
});

export default new FlavorPairingStore();