import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ChipComponent extends Component {

    getChipClassName() {
        var classNameDefault = 'chip',
            className = classNameDefault;

        switch (this.props.background) {
        case 'transparent':
            className = `${className} ${classNameDefault}--transparent`;
            break;
        default:
            break;
        }

        if (this.props.highlightText) {
            className = `${className} ${classNameDefault}--highlight`;
        }

        if (this.props.size) {
            className = `${className} ${classNameDefault}--${this.props.size}`;
        }

        return className;
    }

    getStyle() {
        var style = {};

        if (this.props.maxWidth) {
            style.maxWidth = this.props.maxWidth;
        }

        return style;
    }
}

ChipComponent.propTypes = {
    children: PropTypes.object.isRequired,

    highlightText: PropTypes.bool,
    linkTo: PropTypes.string,
    background: PropTypes.string,
    maxWidth: PropTypes.string,
    size: PropTypes.string,
};

class ChipDefault extends ChipComponent {

    render() {
        return (
            <div
                className={this.getChipClassName()}
                style={this.getStyle()}
            >
                {this.props.children}
            </div>
        );
    }
}

class ChipLink extends ChipComponent {

    render() {
        return (
            <NavLink
                className={this.getChipClassName()}
                to={this.props.linkTo}
                role='button'
                style={this.getStyle()}
            >
                {this.props.children}
            </NavLink>
        );
    }
}

class ChipButton extends ChipComponent {

    render() {
        return (
            <button
                className={this.getChipClassName()}
                style={this.getStyle()}
            >
                {this.props.children}
            </button>
        );
    }
}

class Chip extends Component {

    constructor(props) {
        super(props);
        this.validateProps();
    }

    validateProps() {
        if (this.props.type === 'link' && !this.props.linkTo) {
            throw Error('ChipLink requires prop linkTo');
        }

        if (this.props.image && !this.props.imageAltText) {
            throw Error('Chip image requires prop imageAltText');
        }
    }

    getChipComponent() {
        var chip = null;
    
        switch(this.props.type) {
        case 'button':
            chip = ChipButton;
            break;
        case 'link':
            chip = ChipLink;
            break;
        default:
            chip = ChipDefault;
        }

        return chip;
    }

    render() {
        var chipProps = _.clone(this.props);

        chipProps.children = (
            <React.Fragment>
                {this.props.image && <div
                    className='img--round chip__img'
                    style={{
                        backgroundImage: `url(${this.props.image})`
                    }}
                ></div>}
                
                {this.props.hideText
                    ? null
                    : <div className='chip__text'>{this.props.text}</div>
                }
            </React.Fragment>
        );

        const chip = React.createElement(
            this.getChipComponent(),
            {...chipProps}
        );

        return chip;
    }
}

Chip.propTypes = {
    text: PropTypes.string.isRequired,
    
    background: PropTypes.string,
    hideText: PropTypes.bool,
    highlightText: PropTypes.bool,
    image: PropTypes.string,
    imageAltText: PropTypes.string,
    linkTo: PropTypes.string,
    maxWidth: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
};

export default Chip;