import { getWords } from 'js/utilities/getwords';
import { observer } from 'mobx-react';
import appStore from 'js/stores/App';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ModalConfirm extends Component {

    render() {
        const confirmText = (this.props.confirmText
                || getWords('modal:confirm:yes'));
        
        const cancelText = (this.props.cancelText
                || getWords('modal:confirm:no'));
        
        return (
            <div className='confirm'>
                <h3>{this.props.title}</h3>
                {this.props.description &&
                    <p>{this.props.description}</p>
                }
                <div className="confirm__action">
                    <button
                        className="cancel confirm__cancel"
                        onClick={() => {
                            this.props.callback(false);
                            appStore.modalHide();
                        }}
                    >{cancelText}</button>
                    <button
                        className="confirm__confirmed"
                        onClick={() => {
                            this.props.callback(true);
                            appStore.modalHide();
                        }}
                    >{confirmText}</button>
                </div>
            </div>
        );
    }
}

ModalConfirm.propTypes = {
    callback: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    description: PropTypes.string,
};

export default observer(ModalConfirm);