import {decorate, observable, configure, action} from 'mobx';
import { randomString } from 'js/utilities/randomstring';
import filterStore from 'js/components/filters/store';

configure({enforceActions: 'always'});

class RecipeListStore {

    resetView() {
        this.purgeFilters();
        this.updateFeedReloadKey();
        this.updateFiltersReloadKey();
    }
    
    purgeFilters() {
        filterStore.purgeFilters(this.componentRoute);
        this.filters = {};
    }

    setComponentRoute(name) {
        this.componentRoute = name;
    }

    updateFeedReloadKey() {
        this.feedReloadKey = randomString(6);
    }

    updateFiltersReloadKey() {
        this.filtersReloadKey = randomString(6);
    }

    updateFilters(filters) {
        this.filters = filters;
    }
}

decorate(RecipeListStore, {
    feedReloadKey: observable,
    filters: observable,
    filtersReloadKey: observable,

    purgeFilters: action,
    resetView: action,
    setComponentRoute: action,
    updateFeedReloadKey: action,
    updateFilters: action,
    updateFiltersReloadKey: action,
});

export default new RecipeListStore();