import _ from 'lodash';

export const getWords = (key, context) => {
    var words = window.appWords[key];

    if (context) {
        var compiled = _.template(words);
        return compiled(context);
    }

    return words;
};