import { getConfig } from 'js/utilities/getconfig';
import { getWords } from 'js/utilities/getwords';
import { hasGroup } from 'js/permissions';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import recipeListStore from 'js/components/recipelist/store';
import userStore from 'js/stores/User';


class Usermenu extends Component {

    constructor(props) {
        super(props);
        this.defaultAvatar = getConfig('DEFAULT_AVATAR_IMAGE');
        this.defaultAvatarAlt = getConfig('DEFAULT_AVATAR_IMAGE_ALT');
        this.state = {
            showOptions: false,
            defaultAvatar: this.defaultAvatar,
            mouseOver: false,
        };
    }

    toggleShowOptions(show) {
        this.setState({
            showOptions: show,
        });

        if (!show) {
            this.props.onClose();
        }
    }

    getAvatar() {
        if (userStore.avatar === this.defaultAvatar) {
            return this.state.defaultAvatar;
        }

        return userStore.avatar;
    }

    render() {
        return (
            <div
                className={`usermenu ${
                    this.state.showOptions ? 'usermenu--options' : ''
                }`}
                onClick={() => {
                    if (!this.state.mouseOver) {
                        this.toggleShowOptions(
                            this.state.showOptions ? false : true);
                        this.setState({
                            defaultAvatar: (this.state.showOptions
                                ? this.defaultAvatar : this.defaultAvatarAlt)
                        });
                    }
                }}
                onMouseOver={() => {
                    if (window.innerWidth > window.uiBreakPoints.md) {
                        this.toggleShowOptions(true);
                        this.setState({
                            defaultAvatar: this.defaultAvatarAlt,
                            mouseOver: true,
                        });                        
                    }
                }}
                onMouseLeave={() => {
                    if (window.innerWidth > window.uiBreakPoints.md) {
                        this.toggleShowOptions(false);
                        this.setState({
                            defaultAvatar: this.defaultAvatar,
                            mouseOver: false,
                        });
                    }
                }}
            >
                <div className='usermenu__top'>
                    <div
                        className='img--round usermenu__avatar'
                        style={{
                            backgroundImage: `url(${this.getAvatar()})`
                        }}
                    ></div>
                    <div className='usermenu__name'>
                        {userStore.username}
                    </div>
                </div>
                <div
                    className={`usermenu__options ${
                        this.state.showOptions ? 'usermenu__options--show' : ''
                    }`}
                >
                    <NavLink
                        to='/chef/profile/'
                        role='button'
                        className='usermenu__item'
                        onClick={() => {
                            this.toggleShowOptions(false);
                        }}
                    >
                        {getWords('user:options:myProfile')}
                    </NavLink>
                    <NavLink
                        to={`/recipes/?user__username=${userStore.username}`}
                        role='button'
                        className='usermenu__item'
                        onClick={() => {
                            this.toggleShowOptions(false);
                            recipeListStore.resetView();
                        }}
                    >
                        {getWords('user:options:myRecipes')}
                    </NavLink>
                    <NavLink
                        to='/menus/'
                        role='button'
                        className='usermenu__item'
                    >
                        {getWords('nav:topnav:menus')}
                    </NavLink>
                    <button
                        className='usermenu__item'
                        onClick={() => {
                            userStore.signOut();
                            this.toggleShowOptions(false);
                        }}
                    >{getWords('user:options:signOut')}</button>
                    {hasGroup(window.userGroups.admin) && <React.Fragment>
                        <NavLink
                            to='/admin/'
                            role='button'
                            className='usermenu__item'
                            target="_blank"
                        >
                            Admin API
                        </NavLink>
                        <NavLink
                            to='/adminui/'
                            role='button'
                            className='usermenu__item'
                        >
                            Admin UI
                        </NavLink>
                    </React.Fragment>}
                </div>
            </div>
        );
    }
}

Usermenu.propTypes = {
    onClose: PropTypes.func,
};

export default observer(Usermenu);