import _ from 'lodash';
import { observer } from 'mobx-react';
import appStore from 'js/stores/App';
import historyStore from 'js/stores/History';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Modal extends Component {

    constructor(props) {
        super(props);

        this.modalWrapRef = React.createRef();
        this.viewBlockerClass = 'modal';
    }

    componentDidMount() {
        this.setModalTop();
        appStore.escKeyPush(() => {
            this.hideModal();
        });

        this.setState({
            historyUnlisten: historyStore.history.listen((location, action) => {
                if (action === 'POP') {
                    appStore.modalHide();
                    this.state.historyUnlisten && this.state.historyUnlisten();
                }
            })
        });
    }

    componentDidUpdate() {
        this.setModalTop();
    }

    componentWillUnmount() {
        appStore.escKeyClear();

        if (_.get(this.props.content, 'props.onModalClose')) {
            this.props.content.props.onModalClose();
        }
    }

    setModalTop() {
        this.modalWrapRef.current.style.top = `${window.scrollY}px`;
    }

    hideModal() {
        this.state.historyUnlisten && this.state.historyUnlisten();
        appStore.modalHide();
        this.props.onHide && this.props.onHide();
    }

    getModalWrapClass() {
        var className = 'modal__wrap';

        if (this.props.size === 'large') {
            className = `${className} ${className}--large`;
        }

        if (this.props.size === 'xl') {
            className = `${className} ${className}--xl`;
        }

        return className;
    }

    render() {
        return (
            <div
                className={this.viewBlockerClass}
                onClick={(event) => {
                    // only close if background is clicked
                    if (event.target.className === this.viewBlockerClass) {
                        this.hideModal();
                    }
                }}
            >
                <div
                    ref={this.modalWrapRef}
                    className={this.getModalWrapClass()}
                >
                    <button
                        className='closethis'
                        onClick={() => {
                            this.hideModal();
                        }}
                    >&times;</button>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    content: PropTypes.object.isRequired,

    onHide: PropTypes.func,
    size: PropTypes.string,
};

export default observer(Modal);