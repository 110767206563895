import { getWords } from 'js/utilities/getwords';

function update(meta) {
    if (meta.title) {
        setTitle(meta.title);
    }

    if (meta.description) {
        setDescription(meta.description);
    }
    
    if (meta.image) {
        setOgImage(meta.image);
    }

    if (meta.type) {
        setOgType(meta.type);
    }
    
    if (meta.jsonLd) {
        setJsonLd(meta.jsonLd);
    }

    setOgUrl(window.location.href);
}

function reset() {
    setTitle(getWords('meta:title:default'));
    setDescription(getWords('meta:description:default'));
    setOgImage('');
    setOgType('');
    setOgUrl(window.location.href);
    setJsonLd({});
}

function setMetaValue(selector, attribute, value) {
    var head = document.querySelector('head');

    try {
        var element = head.querySelector(selector);
        element[attribute] = value;
    }
    catch (e) {
        // do nothing
    }
}

function setJsonLd(jsonLd) {
    jsonLd = JSON.stringify(jsonLd);
    setMetaValue(
        'script[type="application/ld+json"]', 'text', jsonLd);
}

function setTitle(title) {
    setMetaValue('title', 'text', title);
    setMetaValue(
        'meta[property="og:title"]',
        'content',
        title
    );
}

function setDescription(description) {
    setMetaValue('meta[name=description]', 'content', description);
    setMetaValue(
        'meta[property="og:description"]',
        'content',
        description
    );
}

function setOgImage(value) {
    setMetaValue(
        'meta[property="og:image"]',
        'content',
        value
    );
}

function setOgUrl(value) {
    setMetaValue(
        'meta[property="og:url"]',
        'content',
        value
    );
}

function setOgType(value) {
    setMetaValue(
        'meta[property="og:type"]',
        'content',
        value
    );
}

export default {
    reset,
    update,
};