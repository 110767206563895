import _ from 'lodash';
import { getConfig } from 'js/utilities/getconfig';
import { getWords } from 'js/utilities/getwords';
import { 
    createQueryString, 
    queryStringToObject
} from 'js/request';
import { withRouter } from 'react-router-dom';
import historyStore from 'js/stores/History';
import filterStore from 'js/components/filters/store';
import InputText from 'js/components/form/inputs/text';
import InputTextArea from 'js/components/form/inputs/textarea';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Svg from 'js/components/svg';
import store from 'js/components/recipesearch/store';

class Search extends Component {

    constructor(props) {
        super(props);
        this.filterStoreComponent = '/recipes/';
        this.inputRefs = {
            search: React.createRef(),
            search_tags: React.createRef(),
            search_tags_exclude: React.createRef(),

        };
        this.defaultValues = this.getQueryParams();
        this.ingredientInputKeys = {};

        this.state = {
            inputsIngredients: [],
            showAdvanced: false,
            highlightAdvanced: false,
        };
    }

    componentDidMount() {
        this.populateInputs();
        this.showHighlightAdvanced(true);
    }

    showHighlightAdvanced(updateState) {
        var keys = _.keys(store.getSearchState()),
            searchKeys = [
                'search',
                'search_ingredients',
                'search_ingredients_exclude',
                'search_tags',
                'search_tags_exclude'
            ],
            show = false;

        _.remove(keys, k => {
            return k === 'search';
        });

        _.forEach(keys, (value) => {
            if (searchKeys.indexOf(value) > -1) {
                show = true;
            }
        });

        if (updateState) {
            this.setState({
                highlightAdvanced: show,
            });
        }

        return show;
    }

    /**
     * Add ingredient search inputs and populate all search
     * inputs with values from URL query params.
     */
    populateInputs() {
        var ingredients = _.map(
            Array(_.get(
                this.defaultValues, 'search_ingredients.length'
            ) || 2),
            () => {
                return this.ingredientInput(true);
            });
        var ingredientsExclude = _.map(
            Array(_.get(
                this.defaultValues, 'search_ingredients_exclude.length'
            ) || 2
            ),
            () => {
                return this.ingredientInput(false);
            });

        this.setState({
            inputsIngredients: ingredients,
            inputsExcludeIngredients: ingredientsExclude,
        });
    }

    getDefaultValue(name) {
        var value = _.get(this.defaultValues, name) || '';

        if (_.isArray(value)) {
            var v = value.shift();
            this.defaultValues[name] = value;

            return v;
        }

        return value;
    }

    /**
     * Get URL query params on page load and format values.
     */
    getQueryParams() {
        var delimiter = getConfig('SEARCH_KEYWORD_DELIMITER');
        var queryParams = {};

        if (_.get(historyStore, 'history')) {
            queryParams = queryStringToObject(
                historyStore.history.location.search);
        }

        _.forEach(queryParams, (value, key) => {
            if (key === 'search') {
                store.setSearchState(key, key, value);
                return;
            }

            value = value.split(delimiter);

            if (_.startsWith(key, 'search_tags')) {
                store.setSearchState(key, key, value);
                value = value.join(', ');
            }
            else {
                _.forEach(value, (v, index) => {
                    var prefix = this.ingredientInputKeyPrefix(
                        !_.endsWith(key, '_exclude')
                    );
                    var i = `${prefix}${index + 1}`;
                    store.setSearchState(key, i, v);
                });
            }

            queryParams[key] = value;
        });

        return queryParams;
    }

    clearSearchInputs() {
        store.purgeSearchState();
        _.forEach(this.inputRefs, ref => {
            if (!ref.current) {
                return;
            }

            var field = (ref.current.querySelector('input')
                || ref.current.querySelector('textarea'));
            
            field.value = '';
        });

        this.setState({
            highlightAdvanced: this.showHighlightAdvanced()
        });
    }

    /**
     * Remove input from multiple input search
     * @param {bool} include exclude or include search term
     * @param {string} key input key
     */
    deleteIngredientInput(include, key) {
        var inputs = include 
            ? this.state.inputsIngredients
            : this.state.inputsExcludeIngredients;

        var index = _.findIndex(inputs, item => {
            return item.key === key;
        });

        if (index === -1) {
            return;
        }

        var input = (this.inputRefs[key]
            .current.querySelector('input'));
            
        if (input) {
            store.deleteSearchState(input.name, input.id);
        }

        if (inputs.length === 1) {
            if (input) {
                input.value = null;
            }
            return;
        }

        inputs.splice(index, 1);

        if (include) {
            this.setState({
                inputsIngredients: inputs
            });
        }
        else {
            this.setState({
                inputsExcludeIngredients: inputs
            });
        }
    }

    addIngredientInput() {
        var inputs = this.state.inputsIngredients;
        inputs.push(this.ingredientInput(true));
        this.setState({
            inputsIngredients: inputs
        });
    }

    addExcludeIngredientInput() {
        var inputs = this.state.inputsExcludeIngredients;
        inputs.push(this.ingredientInput(false));
        this.setState({
            inputsExcludeIngredients: inputs
        });
    }

    toggleShowAdvanced() {
        this.setState({
            showAdvanced: this.state.showAdvanced
                ? false
                : true
        });
    }

    handleSearch(updateView) {
        var pathname = historyStore.history.location.pathname;
        var filters = store.getSearchState();

        if (updateView && pathname !== this.filterStoreComponent) {
            filters = createQueryString(filters);
            historyStore.push({
                pathname: this.filterStoreComponent,
                search: filters,
            });
        }
        else {
            filterStore.setExternalFilters(
                this.filterStoreComponent, filters);
        }

        this.setState({
            highlightAdvanced: this.showHighlightAdvanced(),
            showAdvanced: false,
        });

        this.onExit();
    }

    ingredientInputKeyPrefix(include) {
        return include ? 'rs_i_' : 'rs_ie_';
    }

    getIngredientInputKey(include) {
        var prefix = this.ingredientInputKeyPrefix(include);

        if (!_.get(this.ingredientInputKeys, prefix)) {
            this.ingredientInputKeys[prefix] = [];
        }

        this.ingredientInputKeys[prefix].push(null);

        var key = `${prefix}${this.ingredientInputKeys[prefix].length}`;

        return key;
    }

    btnAdvancedExpandClass() {
        var className = 'btn__transparent search__expand';

        if (this.state.showAdvanced) {
            className = `${className} search__expand--up`;
        }

        if (this.state.highlightAdvanced) {
            className = `${className} search__expand--hladv`;
        }

        return className;
    }

    onExit() {
        this.props.onExit && this.props.onExit();
    }

    ingredientInput(include) {
        var key = this.getIngredientInputKey(include);
        this.inputRefs[key] = React.createRef();
        var name = include
            ? 'search_ingredients'
            : 'search_ingredients_exclude';

        return (
            <div
                className='search__wrap-i'
                key={key}
            >
                <InputText
                    id={key}
                    fieldRef={this.inputRefs[key]}
                    name={name}
                    labelText={include
                        ? getWords('recipeSearch:ingredients:include')
                        : getWords('recipeSearch:ingredients:exclude')
                    }
                    noBorder={true}
                    hideLabel={true}
                    onChange={e => {
                        store.setSearchState(
                            e.target.name,
                            key,
                            e.target.value
                        );
                    }}
                    styling='short'
                    defaultValue={
                        this.getDefaultValue(name)
                    }
                />
                <button
                    data-inputid={key}
                    className='btn__sm search__i-del'
                    onClick={e => {
                        this.deleteIngredientInput(
                            include,
                            e.currentTarget.dataset.inputid);
                    }}
                >
                    <Svg svgId='svgs-delete' />
                </button>
            </div>
        );
    }

    viewAdvancedSearch() {

        return (
            <div className={`search__advanced ${
                this.state.showAdvanced && 'search__advanced--show'
            }`}>
                <div className='search__form'>
                    <div className='search__ingredients'>
                        <div
                            className='search__i search__i--include'
                        >
                            <div className='search__i-inputs'>
                                <p className='search__info'>
                                    {getWords(
                                        'recipeSearch:ingredients:includeInfo'
                                    )}
                                </p>
                                {_.map(this.state.inputsIngredients, input => {
                                    return input;
                                })}
                            </div>
                            
                            <div className='search__i-add-wrap'>
                                <button
                                    className='search__i-add'
                                    onClick={() => {
                                        this.addIngredientInput();
                                    }}
                                >
                                    <Svg svgId='svgs-add' />
                                </button>
                            </div>
                        </div>

                        <div
                            className='search__i search__i--exclude'
                        >
                            <div className='search__i-inputs'>
                                <p className='search__info'>
                                    {getWords(
                                        'recipeSearch:ingredients:excludeInfo'
                                    )}
                                </p>
                                {_.map(this.state.inputsExcludeIngredients,
                                    input => {
                                        return input;
                                    }
                                )}
                            </div>
                            
                            <div className='search__i-add-wrap'>
                                <button
                                    className='search__i-add'
                                    onClick={() => {
                                        this.addExcludeIngredientInput();
                                    }}
                                >
                                    <Svg svgId='svgs-add' />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='search__tags'>
                        <p className='search__info'>
                            {getWords(
                                'recipeSearch:tags:info'
                            )}
                        </p>
                        <div
                            className='search__t'
                        >
                            <InputTextArea
                                id='search_tags'
                                name='search_tags'
                                labelText={
                                    getWords('recipeSearch:tags:include')
                                }
                                defaultValue={
                                    this.getDefaultValue('search_tags')
                                }
                                fieldRef={this.inputRefs.search_tags}
                                hideLabel={true}
                                noBorder={true}
                                height='small'
                                onChange={e => {
                                    store.setSearchState(
                                        e.target.name,
                                        e.currentTarget.id,
                                        e.target.value
                                    );
                                }}
                            />
                        </div>
                        <div
                            className='search__t'
                        >
                            <InputTextArea
                                id='search_tags_exclude'
                                name='search_tags_exclude'
                                defaultValue={
                                    this.getDefaultValue('search_tags_exclude')
                                }
                                labelText={
                                    getWords('recipeSearch:tags:exclude')
                                }
                                fieldRef={this.inputRefs.search_tags_exclude}
                                hideLabel={true}
                                noBorder={true}
                                height='small'
                                onChange={e => {
                                    store.setSearchState(
                                        e.target.name,
                                        e.currentTarget.id,
                                        e.target.value
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='search__form-btns'>
                    <button
                        className='btn__sm btn__tertiary'
                        onClick={() => {
                            this.toggleShowAdvanced();
                            this.onExit();
                        }}
                    >
                        {getWords('recipeSearch:form:cancel')}
                    </button>
                    <button
                        className='btn__sm btn__tertiary'
                        onClick={() => {
                            this.clearSearchInputs();
                        }}
                    >
                        {getWords('recipeSearch:form:clear')}
                    </button>
                    <button
                        className='search__icon'
                        onClick={() => {
                            this.handleSearch(true);
                        }}
                    >
                        <Svg svgId='svgs-search' />
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                className={
                    `search ${this.props.className && this.props.className}`
                }
            >
                <div className='search__bar'>
                    <button 
                        className={this.btnAdvancedExpandClass()}
                        onClick={() => {
                            this.toggleShowAdvanced();
                        }}
                    />
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            this.handleSearch(true);
                        }}
                    >
                        <InputText
                            id='search_bar'
                            fieldRef={this.inputRefs.search}
                            name='search'
                            defaultValue={
                                this.getDefaultValue('search')
                            }
                            labelText={getWords('recipeSearch:label:search')}
                            noBorder={true}
                            hideLabel={true}
                            onChange={e => {
                                store.setSearchState(
                                    e.target.name,
                                    'search',
                                    e.target.value
                                );
                            }}
                            styling='short'
                        />
                        <button
                            className='btn__transparent search__icon'
                        >
                            <Svg svgId='svgs-search' />
                        </button>
                    </form>
                </div>
                {this.viewAdvancedSearch()}
            </div>
        );
    }
}

Search.propTypes = {
    className: PropTypes.string,
    expand: PropTypes.bool,
    inline: PropTypes.bool,
    onExit: PropTypes.func,
};

export default withRouter(Search);