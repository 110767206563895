import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import { observer } from 'mobx-react';
import inputProps from 'js/components/form/inputs/inputProps';
import inputStore from 'js/components/form/inputs/inputStore';
import InputUtils from 'js/components/form/inputs/inputUtils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class InputText extends Component {

    constructor(props) {
        super(props);
        _.each(InputUtils, (item,key) => this[key] = _.isFunction(item)
            ? item.bind(this) : item
        );
        this.state = {
            inputValue: null,
            errorText: null,
        };
    }

    componentDidMount() {
        this.onMountHandleLabels();
    }

    render() {
        return (
            <div className={this.getStylingClassName('form__field')}>
                <div
                    className={this.getFieldWrapClass(this.state)}
                    style={this.props.styleWrap}
                    ref={this.props.fieldRef}
                >
                    {this.props.leadingIcon}
                    <div className={inputStore.fieldWrapClass}>
                        {this.props.noLabel
                            ? null
                            : <label
                                className={inputStore.labelClass}
                                htmlFor={this.props.id}
                            >
                                {this.props.labelText}
                                {this.props.required
                                    ? <span
                                        className={
                                            inputStore.inputRequiredClass
                                        }
                                    >*</span>
                                    : null
                                }
                            </label>
                        }
                        <input
                            id={this.props.id}
                            autoComplete={
                                !this.props.autocomplete ? 'off' : 'on'
                            }
                            aria-label={this.props.name}
                            aria-required={this.props.required || false}
                            className={
                                `field__input ${this.props.className || ''}`
                            }
                            name={this.props.name}
                            onChange={e => {
                                // Calling onFocus here moves the label for
                                // autofilled inputs
                                this.onFocus(e); 
                                this.props.onChange(e);
                                this.setState({
                                    errorText: null,
                                    inputValue: e.target.value,
                                });
                            }}
                            onBlur={(e) => {
                                this.onBlur(e);
                            }}
                            onFocus={(e) => {
                                this.onFocus(e);
                            }}
                            ref={element => {this[this.props.name] = element;}}
                            style={this.props.styleInput}
                            type={this.props.type || 'text'}
                            defaultValue={this.props.defaultValue}
                        />
                        
                        {this.getErrorText(this.state)
                            ? <div className={inputStore.fieldErrorClass}>
                                {this.getErrorText(this.state)}
                            </div>
                            : null
                        }                    
                    </div>
                </div>
                {this.props.onInputSave
                    ? <button
                        className='field__save'
                        onClick={e => {
                            e.preventDefault();
                            this.onInputSave();
                        }}
                    >
                        {getWords('form:input:save')}
                    </button>
                    : null
                }
            </div>
        );
    }
}

var componentProps = {
    name: PropTypes.string.isRequired,
};

InputText.propTypes = {...inputProps, ...componentProps};

export default observer(InputText);
