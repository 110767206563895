import _ from 'lodash';
import appStore from 'js/stores/App';

window.showSignInPaths = [
    '/chef/profile',
];

window.uiBreakPoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};

function loadUrls() {
    var apiurls = {
        accountConfirm: '/api/v1/auth/account/confirm/<%= token %>',
        adminReviewLemmas: '/api/v1/classifyfood/lemmas',
        adminReviewLemmasCount: '/api/v1/classifyfood/lemmas/count',
        avatar: '/api/v1/avatar',
        commentsRecipeCreate: '/api/v1/comments/recipe/<%= recipeId %>',
        blogHome: '/api/v1/pages/blog/latestposts',
        blogLatestPosts: '/api/v1/pages/blog/latestposts',
        blogContent: '/api/v1/pages/blog/<%= slug %>',
        commentDetail: '/api/v1/comments/<%= id %>',
        contentDetail: '/api/v1/content/<%= slug %>',
        contentStatic: '/api/v1/content/static/<%= slug %>',
        emailUnsubscribe: '/api/v1/email/unsubscribe/<%= token %>',
        flavorPairings: '/api/v1/ingredientpairing',
        flavorPairingsAutoComplete: '/api/v1/ingredientpairing/autocomplete',
        flavorPairingAffinities:
            '/api/v1/ingredientpairing/affinities/<%= slug %>',
        getAllWords: '/api/v1/words',
        getConfig: '/api/v1/config',
        lemmaDetail: '/api/v1/classifyfood/lemmas/<%= id %>',
        menuDetail: '/api/v1/menus/<%= slug %>',
        menuList: '/api/v1/menus',
        menuRecipe: '/api/v1/menus/<%= slug %>/recipe/<%= recipeId %>',
        passwordResetEmail: '/api/v1/auth/forgotpassword',
        passwordReset: '/api/v1/auth/forgotpassword/<%= token %>',
        recipeBuilder: '/api/v1/recipebuilder',
        recipeDetail: '/api/v1/recipes/<%= slug %>',
        recipeEdit: '/api/v1/recipes/<%= slug %>/edit',
        recipeImage: '/api/v1/recipes/<%= slug %>/edit/image',
        recipeSaveToFave: '/api/v1/recipes/<%= slug %>/favorite',
        recipeList: '/api/v1/recipes',
        recipeAllFavorites: '/api/v1/recipes/user/allfavorites',
        recipeRating: '/api/v1/recipes/<%= slug %>/rating',
        signIn: '/api/v1/auth/signin',
        signUp: '/api/v1/auth/signup',
        signOut: '/api/v1/auth/signout',
        tags: '/api/v1/tags',
        tooltipDeleteAccount: '/api/v1/cms/pages/profile-delete-my-account',
        tooltipRBIngredients: '/api/v1/cms/pages/recipe-builder-ingredients',
        tooltipRBSteps: '/api/v1/cms/pages/recipe-builder-steps',
        tooltipRBStyle: '/api/v1/cms/pages/recipe-builder-styling',
        user: '/api/v1/auth/user',
        userPublicProfile: '/api/v1/users/<%= username %>/public',
        wikipediaAutoComplete: '/api/v1/wikipedia/page/autocomplete',
        wikipediaPageDetail: '/api/v1/wikipedia/page/<%= id %>',
        wikipediaPageList: '/api/v1/wikipedia/page',
    };
    
    window.apiurls = window.apiurls || {};
    var apiHostname = window.APP_UI_HOSTNAME
        ? `https://${window.APP_UI_HOSTNAME}` : '';
    _.forEach(apiurls, (value, key) => {
        window.apiurls[key] = `${apiHostname}${value}`;
    });
}

window.userGroups = {
    admin: 'admin',
    flavorPairings: 'flavorPairings',
};

function escKeyHookEvent() {
    document.onkeydown = function(event) {
        event = event || window.event;
        var isEsc = false;
        
        if ('key' in event) {
            isEsc = (event.key === 'Escape' || event.key === 'Esc');
        }
        else {
            isEsc = (event.keyCode === 27);
        }

        if (isEsc) {
            appStore.escKeyHook();
        }
    };
}

export default function setup() {
    loadUrls();
    escKeyHookEvent();
}