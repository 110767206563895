import _ from 'lodash';
import {decorate, observable, configure, action} from 'mobx';
import React from 'react';

configure({enforceActions: 'always'});

class FeedStore {

    constructor() {
        this.feeds = {};
    }

    tearDown(name) {
        delete this.feeds[name];
    }

    getFeed(name) {
        return this.feeds[name];
    }

    validateData(data) {
        if (_.isUndefined(data.count)) {
            throw new Error('feed count is required');
        }
        if (_.isUndefined(data.next)) {
            throw new Error('feed next is required');
        }
        if (_.isUndefined(data.previous)) {
            throw new Error('feed previous is required');
        }
        if (_.isUndefined(data.results)) {
            throw new Error('feed results is required');
        }

        return true;
    }

    create(name, data) {
        if (this.validateData(data)) {
            this.feeds[name] = data;
        }
    }

    update(name, data) {
        if (this.validateData(data)) {
            var results = this.feeds[name].results.concat(data.results);
            this.feeds[name] = data;
            this.feeds[name].results = results;
        }
    }
    
    view(name, Component, componentProps, componentPropsMap) {
        var items = [],
            props = {},
            data = this.feeds[name];

        _.forEach(data.results, (item, index) => {
            props = componentProps || {};
            _.forEach(componentPropsMap, (itemAttribute, prop) => {
                props[prop] = _.get(item, itemAttribute);
            });

            items.push(
                <Component
                    key={`feed-${name}-${index}`}
                    {...props}
                />
            );
        });

        return items;
    }

    pubFeedPopulated() {
        _.forEach(this.pubSubFeedPopulated, (event, name) => {
            event(this.feeds[name]);
        });
    }

    subFeedPopulated(key, callback) {
        if (!this.pubSubFeedPopulated) {
            this.pubSubFeedPopulated = {};
        }

        if (!_.get(this.pubSubFeedPopulated, key)) {
            this.pubSubFeedPopulated[key] = null;
        }

        this.pubSubFeedPopulated[key] = callback;
    }
}

decorate(FeedStore, {
    pubSubFeedPopulated: observable,

    create: action,
    getFeed: action,
    subFeedPopulated: action,
    tearDown: action,
    update: action,
    view: action,
});

export default new FeedStore();