import _ from 'lodash';
import { getWords } from 'js/utilities/getwords';
import appStore from 'js/stores/App';
import React from 'react';

function check(response) {
    var responseVersion = _.get(response, 'headers.app-version-hash'),
        windowVersion = document.head.querySelector(
            'meta[name=app-version-hash]');

    windowVersion = _.get(windowVersion, 'content');

    if (!responseVersion || !windowVersion) {
        return;
    }

    if (responseVersion === '0' || windowVersion === '0') {
        return;
    }

    if (responseVersion !== windowVersion) {
        appStore.modalShow(() => {
            return (
                <React.Fragment>
                    <h2>{getWords('appVersion:reload:title')}</h2>
                    <p>{getWords('appVersion:reload:description')}</p>
                    <button
                        onClick={() => {
                            window.location.reload(true);
                        }}
                    >
                        {getWords('appVersion:reload:button')}
                    </button>
                </React.Fragment>
            );
        });
    }
}

export default {
    check,
};